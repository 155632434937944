
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson = window.Smartly.locales.modules.en.back_royal.lessons.lesson || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component.layout = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component.layout || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive = {...window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component.layout.text_image_interactive, ...{
    "give_me_a_hint": "Give me a hint"
}
}
    