
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson = window.Smartly.locales.modules.en.back_royal.lessons.lesson || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component.answer_list = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component.answer_list || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons = {...window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component.answer_list.answer_list_buttons, ...{
    "poll_label_0": "A",
    "poll_label_1": "B",
    "poll_label_2": "C",
    "poll_label_3": "D",
    "poll_label_4": "E",
    "poll_label_5": "F",
    "poll_label_6": "G",
    "poll_label_7": "H",
    "poll_label_8": "I",
    "poll_label_9": "J",
    "poll_label_10": "K",
    "select_multiple_answers": "Select multiple answers",
    "select_all_answers": "Select all answers"
}
}
    