
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_offline_box = window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_offline_box || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_offline_box = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_offline_box, ...{
    "you_are": "YOU ARE",
    "offline": "OFFLINE",
    "keep_going": "Keep going! We will sync your progress when we detect an internet connection.",
    "you_are_offline": "You Are Offline"
}
}
    