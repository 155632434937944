
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_completed = window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_completed || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_completed = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_completed, ...{
    "course_complete": "COURSE COMPLETE",
    "exam_complete": "EXAM COMPLETE",
    "continue": "CONTINUE",
    "return_to_dashboard": "RETURN TO DASHBOARD",
    "exam_is_complete": "Your exam is complete. We will calculate your scores and factor them into your final grade.",
    "share_with_friend": "Feel free to share with a friend who might benefit from {{brandName}}'s courses.",
    "great_job": "GREAT JOB!"
}
}
    