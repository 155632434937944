
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.toggleable_key_terms_list_buttons = window.Smartly.locales.modules.en.back_royal.lessons.stream.toggleable_key_terms_list_buttons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.toggleable_key_terms_list_buttons = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.toggleable_key_terms_list_buttons, ...{
    "alphabetical_order": "Alphabetical Order",
    "group_by_lesson": "Group by Lesson"
}
}
    