
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_program_box = window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_program_box || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_program_box = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_program_box, ...{
    "apply_now": "Complete Application",
    "download_certificate": "Download Certificate",
    "register": "Register Now",
    "mobile_status_applied": "Application submitted!",
    "mobile_status_pre_accepted_registered": "You are registered <br> for the class.",
    "mobile_status_pre_accepted_not_registered": "Application accepted!",                                 
    "mobile_status_pre_accepted": "Application accepted!",
    "mobile_status_accepted": "Application accepted!",
    "mobile_status_rejected": "Sorry, you were not<br>accepted. <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">Reapply!</span>",
    "mobile_status_rejected_after_pre_accepted": "Sorry, you were not<br>accepted. <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">Reapply!</span>",
    "mobile_status_rejected_no_reapply_cta": "Sorry, you were not<br>accepted.",
    "mobile_status_rejected_after_pre_accepted_no_reapply_cta": "Sorry, you were not<br>accepted.",
    "mobile_status_expelled": "Your enrollment is <br> no longer active.",
    "mobile_status_expelled_no_reapply_cta": "Your enrollment is <br> no longer active.",
    "mobile_status_foundations_complete": "Application submitted!",
    "mobile_status_deferred": "You’ve deferred your enrollment.",
    "mobile_status_program_failed": "You received a failing <br>score. <span class=\"faq-link\" ng-click=\"openFAQ()\">Visit our FAQ</span>.",
    "mobile_status_program_complete": "You have completed the program.",
    "mobile_status_not_applied": "The deadline for the next admission cycle is approaching.",
    "mobile_status_pre_accepted_not_registered_not_open": "Registration opens on <br> {{registrationOpenDate}}.",
    "progress_application": "Application",
    "progress_foundations": "Business Foundations",
    "progress_response": "Response",
    "progress_begin": "Orientation",
    "progress_decision": "Decision",
    "mba_programs": "{{brandName}} MBA Programs",
    "msse_program": "{{brandName}} MSSE Program",
    "msba_program": "{{brandName}} MSBA Program",
    "apply_to": "Apply to {{brandName}}",
    "application_received": "Application Received",
    "view_degree_faq": "View FAQ",
    "congrats": "Congratulations, <br class=\"visible-md visible-lg\" /> You're In!",
    "close": "Close <i class=\"fas fa-multiply\"></i>",
    "view_status": "View Status <i class=\"fas fa-chevron-up\"></i>",
    "certificate_help_text": "Log in using a desktop computer to download your certificate.",
    "subtitle_not_applied": "The deadline for the next admission cycle is approaching.",
    "subtitle_not_applied_nominee": "As a select {{brandName}} Nominee, you'll receive preferential Admissions consideration.",
    "subtitle_degree_program_applied": "Application received! Complete <strong>{{title}}</strong> to demonstrate your readiness for the degree. Final decisions are sent on {{decisionDate}}. Good luck!",
    "subtitle_degree_program_applied_foundations_complete": "Great work! You'll hear from us soon regarding your application.",
    "subtitle_not_registered": "You have been admitted to the upcoming {{brandName}} {{programTitle}} class, joining an exceptional group of global students. To confirm your place, register by {{registrationDeadline}}.",
    "subtitle_not_registered_not_open": "Congratulations on your acceptance to the {{brandName}} {{programTitle}}! Registration opens on {{registrationOpenDate}}.",
    "subtitle_registered": "<p>Congratulations! You have registered and secured your place in the {{brandName}} {{programTitle}}. You're about to join a class of exceptional students from around the world.</p> <p>Your program starts on {{startDateLong}}. Keep an eye out for notifications via email and on your dashboard regarding orientation events and course access.</p>",
    "subtitle_registered_program_guide": "<p>Congratulations! You have registered and secured your place in the {{brandName}} {{programTitle}}. You're about to join a class of exceptional students from around the world.</p> <p>Your program starts on {{startDateLong}}. Keep an eye out for notifications via email and on your dashboard regarding orientation events and course access.</p> <p>If you haven’t already, review the <a href=\"{{programGuideUrl}}\" target=\"_blank\">Program Guide</a> for more details about your courses and schedule.</p>",
    "subtitle_registered_no_orientation": "<p>Congratulations! You have registered and secured your place in the {{brandName}} {{programTitle}}. You're about to join a class of exceptional students from around the world.</p> <p>Your program starts on {{startDateLong}}. Keep an eye out for notifications via email and on your dashboard regarding course access.</p>",
    "subtitle_registered_no_orientation_program_guide": "<p>Congratulations! You have registered and secured your place in the {{brandName}} {{programTitle}}. You're about to join a class of exceptional students from around the world.</p> <p>Your program starts on {{startDateLong}}. Keep an eye out for notifications via email and on your dashboard regarding course access.</p> <p>If you haven’t already, review the <a href=\"{{programGuideUrl}}\" target=\"_blank\">Program Guide</a> for more details about your courses and schedule.</p>",
    "subtitle_generic_applied": "Application received! Complete <strong>{{title}}</strong> to demonstrate your readiness for the program. Good luck!",
    "subtitle_generic_applied_foundations_complete": "Great work! You'll hear from us soon regarding your application.",
    "subtitle_generic_accepted": "Complete all {{numConcentrations}} concentrations to complete the program.",
    "subtitle_generic_accepted_not_started": "Welcome to the {{programTitle}}! To get started, complete <strong>{{title}}</strong>, the first of {{numConcentrations}} concentrations in the curriculum.<br><br>Complete all {{numConcentrations}} to complete the program.",
    "subtitle_generic_accepted_not_started_foundations_complete": "Welcome to the {{programTitle}}! You've already completed <strong>{{title}}</strong>, the first of {{numConcentrations}} concentrations in the curriculum.<br><br>Complete all {{numConcentrations}} to complete the program.",
    "subtitle_generic_program_complete": "Congratulations on completing the {{programTitle}}.",
    "subtitle_generic_program_failed": "Complete all {{numConcentrations}} concentrations to complete the program.",
    "subtitle_generic_deferred": "You've deferred your {{programTitle}} enrollment. If you have any questions, please email us at <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>.",
    "subtitle_generic_expelled": "Your enrollment is no longer active. We hope you enjoy continued access to select courses from the full program.",
    "subtitle_generic_expelled_no_reapply_cta": "Your enrollment is no longer active. We hope you enjoy continued access to select courses from the full program.",
    "subtitle_generic_rejected": "Sorry, your application wasn’t accepted for the {{programTitle}}.",
    "subtitle_generic_rejected_after_pre_accepted": "Sorry, your application wasn’t accepted for the {{programTitle}}.",
    "subtitle_generic_rejected_no_reapply_cta": "Sorry, your application wasn’t accepted for the {{programTitle}}.",
    "subtitle_generic_rejected_after_pre_accepted_no_reapply_cta": "Sorry, your application wasn’t accepted for the {{programTitle}}.",
    "subtitle_degree_program_accepted": "Complete all {{numConcentrations}} concentrations to qualify for the degree. For the program schedule and complete requirements, <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our degree FAQ</span>.",
    "subtitle_degree_program_accepted_not_started": "Welcome to the {{brandName}} {{programTitle}}! To get started, complete <strong>{{title}}</strong>, the first of {{numConcentrations}} concentrations in the {{programTitle}} curriculum.<br><br>Complete all {{numConcentrations}} to qualify for the degree. For the program schedule and complete requirements, <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our degree FAQ</span>.",
    "subtitle_degree_program_accepted_not_started_foundations_complete": "Welcome to the {{brandName}} {{programTitle}}! You've already completed <strong>{{title}}</strong>, the first of {{numConcentrations}} concentrations in the {{programTitle}} curriculum.<br><br>Complete all {{numConcentrations}} to qualify for the degree. For the program schedule and complete requirements, <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our degree FAQ</span>.",
    "subtitle_degree_program_program_complete": "Complete all {{numConcentrations}} concentrations to qualify for the degree. For the program schedule and complete requirements, <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our degree FAQ</span>.",
    "subtitle_degree_program_program_failed": "Complete all {{numConcentrations}} concentrations to qualify for the degree. For the program schedule and complete requirements, <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our degree FAQ</span>.",
    "subtitle_degree_program_rejected": "Sorry, your application wasn’t accepted to the degree program. However, you can continue learning with our open courses to advance your knowledge, absolutely free, and you are now eligible to <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">reapply for the next class</span>.",
    "subtitle_degree_program_rejected_no_reapply_cta": "Though you won’t be joining the degree program, you can reapply beginning {{reapplicationDate}}. In the meantime, you’ll maintain free access to the open courses.",
    "subtitle_degree_program_rejected_after_pre_accepted": "Sorry, you weren't able to join the degree program previously. However, you can continue learning with our open courses to advance your knowledge, absolutely free, and you are now eligible to <span class=\"faq-link\" ng-click=\"gotoMbaSettings()\">reapply for the next class</span>.",
    "subtitle_degree_program_rejected_after_pre_accepted_no_reapply_cta": "Though you won’t be joining the degree program, you can reapply beginning {{reapplicationDate}}. In the meantime, you’ll maintain free access to the open courses.",
    "subtitle_degree_program_expelled": "Your {{programTitle}} status is no longer active. We hope you enjoy continued access to select courses from the full program.<br/><br/>For more information, contact us at <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>",
    "subtitle_degree_program_expelled_no_reapply_cta": "Your {{programTitle}} status is no longer active. We hope you enjoy continued access to select courses from the full program.<br/><br/>For more information, contact us at <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>",
    "subtitle_degree_program_deferred": "You've deferred your {{programTitle}} enrollment. Please note that you are only allowed to defer enrollment twice before you have to reapply to the {{programTitle}}. If you have any questions, please email us at <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>.",
    "subtitle_career_network_only_accepted": "Welcome to the Career Network! You may sample our open courses while you wait for inquiries from companies.",
    "subtitle_career_network_only_accepted_not_started": "Welcome to the Career Network! You may sample our open courses while you wait for inquiries from companies.",
    "subtitle_career_network_only_accepted_not_started_foundations_complete": "Welcome to the Career Network! You may sample our open courses while you wait for inquiries from companies.",
    "subtitle_career_network_only_rejected": "Sorry, your application wasn’t accepted to the Career Network.",
    "subtitle_career_network_only_rejected_no_reapply_cta": "Sorry, your application wasn’t accepted to the Career Network.",
    "subtitle_career_network_only_expelled": "Your Career Network status is no longer active.",
    "subtitle_career_network_only_expelled_no_reapply_cta": "Your Career Network status is no longer active.",
    "subtitle_certificates_accepted": "Complete all {{numConcentrations}} concentrations to qualify for the certificate. For more details, <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our FAQ</span>.",
    "subtitle_certificates_accepted_not_started": "Welcome to the {{programTitle}} program! To get started, complete <strong>{{title}}</strong>, the first of {{numConcentrations}} concentrations in the curriculum.<br><br>Complete all {{numConcentrations}} to qualify for the certificate. For more details, <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our FAQ</span>.",
    "subtitle_certificates_accepted_not_started_foundations_complete": "Welcome to the {{programTitle}} program! You've already completed <strong>{{title}}</strong>, the first of {{numConcentrations}} concentrations in the curriculum.<br><br>Complete all {{numConcentrations}} to qualify for the certificate. For more details, <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our FAQ</span>.",
    "subtitle_certificates_applied": "Application received! Complete <strong>{{title}}</strong> to demonstrate your readiness for the program. Good luck!",
    "subtitle_certificates_applied_foundations_complete": "Great work! You'll hear from us soon regarding your application.",
    "subtitle_certificates_program_complete": "Congratulations on completing the {{programTitle}}.",
    "subtitle_certificates_program_failed": "Unfortunately, your combined final score did not meet the minimum required for completion of the certificate. For more details <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our FAQ</span>.",
    "subtitle_certificates_rejected": "Sorry, your application wasn’t accepted for the {{programTitle}} program.",
    "subtitle_certificates_rejected_after_pre_accepted": "Sorry, your application wasn’t accepted for the {{programTitle}} program.",
    "subtitle_certificates_rejected_no_reapply_cta": "Sorry, your application wasn’t accepted for the {{programTitle}} program.",
    "subtitle_certificates_rejected_after_pre_accepted_no_reapply_cta": "Sorry, your application wasn’t accepted for the {{programTitle}} program.",
    "subtitle_certificates_expelled": "Your enrollment is no longer active. We hope you enjoy continued access to select courses from the full program.",
    "subtitle_certificates_expelled_no_reapply_cta": "Your enrollment is no longer active. We hope you enjoy continued access to select courses from the full program.",
    "subtitle_jordanian_math_accepted": "Welcome to the {{programTitle}} program.",
    "subtitle_jordanian_math_accepted_not_started": "Welcome to the {{programTitle}} program! To get started, complete <strong>{{title}}</strong>, the first of {{numConcentrations}} concentrations in the curriculum.",
    "subtitle_jordanian_math_accepted_not_started_foundations_complete": "Welcome to the {{programTitle}} program! You've already completed <strong>{{title}}</strong>, the first of {{numConcentrations}} concentrations in the curriculum.",
    "subtitle_jordanian_math_program_complete": "Congratulations on completing the {{programTitle}} program.",
    "subtitle_jordanian_math_program_failed": "Unfortunately, your combined final score did not meet the minimum required for completion of the program. For more details <span class=\"faq-link\" ng-click=\"openFAQ()\">visit our FAQ</span>.",
    "subtitle_jordanian_math_expelled": "Your enrollment is no longer active. We hope you enjoy continued access to select courses from the full program.",
    "subtitle_jordanian_math_expelled_no_reapply_cta": "Your enrollment is no longer active. We hope you enjoy continued access to select courses from the full program."
}
}
    