
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.has_toggleable_display_mode = window.Smartly.locales.modules.en.back_royal.lessons.stream.has_toggleable_display_mode || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.has_toggleable_display_mode = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.has_toggleable_display_mode, ...{
    "other": "Other"
}
}
    