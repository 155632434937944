
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.featured_events = window.Smartly.locales.modules.en.back_royal.lessons.stream.featured_events || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.featured_events = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.featured_events, ...{
    "upcoming_events": "Upcoming Events",
    "featured_events": "Featured Events",
    "view_featured_events": "View Featured Events",
    "view_more_upcoming_events": "View More Events",
    "view_all_events": "View All Events",
    "view_event_tooltip_details_unavailable": "Event details are only available to MBA and Executive MBA students and alumni."
}
}
    