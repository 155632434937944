
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson = window.Smartly.locales.modules.en.back_royal.lessons.lesson || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.show_lesson = window.Smartly.locales.modules.en.back_royal.lessons.lesson.show_lesson || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.show_lesson = {...window.Smartly.locales.modules.en.back_royal.lessons.lesson.show_lesson, ...{
    "this_exam_is_now_closed": "This exam is now closed.",
    "return_to_dashboard": "Return to Dashboard"
}
}
    