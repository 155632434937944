
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_schedule = window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_schedule || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_schedule = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_schedule, ...{
               
    "required_courses": "Required Courses",
    "project_resources": "Project Resources",
    "optional_courses": "Optional Courses",
    "view_full_schedule": "View Full Schedule",
    "submit_to_projects": "Submit to <a href=\"mailto:{{emailAddress}}\">{{emailAddress}}</a>",
    "no_events_found": "No events found.",
    "multiple_times": "Multiple Times",
    "select_an_event": "Select an Event",
    "today": "Today",
    "full_schedule": "Full Schedule",

                
    "at": "at",
    "in_the_room": "in the <b>{{room}}</b> room"
}
}
    