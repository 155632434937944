
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_dashboard = window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_dashboard || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_dashboard = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_dashboard, ...{
    "add_to_my_courses": "ADD TO MY COURSES",
    "additional_resources": "Additional Resources",
    "beta_message": "Beta courses are still in progress! They're available to adventurous learners who want to try early versions of lessons.",
    "chapter_list_caption": "{number, plural, =1{CHAPTER} other{CHAPTERS}}",                                                                        
    "chapter_number_x": "CHAPTER {{number}}",
    "chapter_number_x_lower": "Chapter {{number}}",
    "coming_soon": "Coming Soon!",
    "coming_soon_block": "COMING<br>SOON",
    "coming_soon_inline": "COMING SOON",
    "coming_soon_come_back_later": "You've completed all available Beta lessons! <br class='hidden-xs hidden-sm'> Check back later when more are published.",
    "complete_to_unlock_x": "Complete to unlock <strong>{{title}}</strong>",
    "course_details": "COURSE<br>DETAILS",
    "course_highlights": "COURSE HIGHLIGHTS",
    "course_information": "COURSE INFORMATION",
    "course_summaries": "Course Summaries",
    "credits": "CREDITS",
    "download_certificate": "Download Certificate",
    "explore_key_terms": "EXPLORE KEY TERMS",
    "join": "Join {{brandName}}",
    "join_for_upsell_desc": "Access to {{brandName}}'s course library requires an account.",
    "key_terms": "KEY TERMS",
    "key_terms_description": "Key terms are concepts that {{brandName}} believes deserve special attention when studying this subject. Tap any key term in the list below to navigate directly to the lesson in which it was introduced.",
    "learn_more": "LEARN MORE",
    "lesson_number_x": "LESSON {{number}}",
    "lesson_number_x_lower": "Lesson {{number}}",
    "login": "LOGIN",
    "minutes": "MINUTES",
    "or": "OR",
    "ready_to_begin_start_learning": "Ready to begin? <br class=\"visible-xs\"> Start learning now.",
    "recommended_study": "RECOMMENDED STUDY",
    "related_courses": "RELATED COURSES",
    "resources": "RESOURCES",
    "saved_to_my_courses": "SAVED TO MY COURSES",
    "signing_certificate": "Signing Certificate",
    "show_all": "SHOW ALL",
    "smartcase": "SMARTCASE",
    "test": "TEST",
    "sorry_subscription_required": "Sorry! You need a subscription to access this course.",
    "start_course": "START COURSE",
    "start_learning_now": "Start learning now.",
    "subscribe_to_unlock": "Subscribe to Unlock",
    "subscription_required": "Subscription Required",
    "tap_to_download": "Tap to download",
    "tap_to_remove": "Tap to Remove",
    "unlocked": "Unlocked!",
    "we_suggest_following_x": "We suggest you have familiarity with the material in the following courses before beginning <strong>{{title}}</strong>:",
    "num_lessons_completed": "{{count}} of {{total}} lessons completed",
    "num_lessons_completed_short": "{{count}} of {{total}} lessons",
    "ready_to_begin_exam": "Ready to begin? Start your exam now.",
    "you_will_have_x_hours": "You’ll have {{numHours}} hours from the time you start the exam to complete it.",
    "start": "START",
    "start_exam": "Start Exam",
    "exam_timer": "EXAM TIMER",
    "after_launching_first_you_will_have": "After launching the first lesson, you will have <div class='time-left'>{{timeLeft | hoursMinutesSeconds}}</div> to complete the exam.",
    "after_launching_next_you_will_have": "After launching the next lesson, you will have <div class='time-left'>{{timeLeft | hoursMinutesSeconds}}</div> to complete the exam.",
    "you_have_x_remaining": "YOU HAVE <div class='time-left'>{{timeLeft | hoursMinutesSeconds}}</div> REMAINING",
    "schedule": "SCHEDULE",
    "exam_begins": "EXAM BEGINS",
    "exam_ends": "EXAM ENDS",
    "time_limit": "TIME LIMIT",
    "the_exam_time_will_begin": "The exam timer will begin when you launch your first lesson. After {{timeLimitHours}} hours, your exam will close, and no additional answers will be accepted.",
    "view_schedule": "VIEW SCHEDULE",
    "ready_to_begin": "Ready to begin?",
    "completion_instructions_for_summary": "Complete {{titlesText}} to unlock!",
    "exam_score": "Exam Score",
    "external_links": "ASSIGNED READING",
    "external_links_intro": "{{institutionBrandName}} lessons often link to external articles and other webpages where students can learn more about a particular subject. Students are expected to read these articles as part of the completion of each course. Tap on any link below to navigate to the URL.",
    "title_not_available": "Title Not Available",
    "optional": "Optional",
    "course_description": "Course Description",
    "completed_score": "Completed: {{score}}%",

                                   
    "ready_to_begin_verify_identity": "Ready to begin? Verify your identity to unlock the exam.",
    "ready_to_continue_verify_identity": "Ready to continue? Verify your identity to unlock the exam.",
    "verified_identity_thanks": "Thanks for verifying your identity. Start your exam now.",
    "verify_identity": "VERIFY IDENTITY"
}
}
    