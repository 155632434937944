/* Once browsers widely support container queries, we could use that instead of this.
    For now, we are adding classes onto the `frame-container` indicating its size
    and then using those classes throughout the css for the components that will
    be inside of the frame container.
    We can't use media queries because the frame container does not always stretch
    from the left of the screen to the right */

import { type AnyObject } from '@Types';

type SizeClasses = 'lte-xs' | 'gte-iphone6' | 'gte-sm' | 'gte-md';

export function getFrameContainerSizeClasses(containerWidth: number): SizeClasses[] {
    const classRanges: Record<SizeClasses, [number, number]> = {
        'lte-xs': [0, 767],

        'gte-iphone6': [321, Infinity],
        'gte-sm': [768, Infinity],
        'gte-md': [992, Infinity],
    };

    const classes = Object.keys(classRanges) as SizeClasses[];
    return classes.filter(className => {
        const [min, max] = classRanges[className];
        return containerWidth >= min && containerWidth <= max;
    });
}

type CustomScope = ng.IScope & AnyObject;

export function watchFrameContainerSizeChanges(
    propName: string,
    {
        window,
        scope,
        elem,
        selector = '.frame-container',
    }: { window: ng.IWindowService; scope: CustomScope; elem: JQuery; selector?: string },
) {
    function setFrameContainerSizeClasses() {
        const containerWidth = elem.find(selector).width()!;
        scope[propName] = getFrameContainerSizeClasses(containerWidth);
    }

    let i = 0;
    function ensureSetInitially() {
        if (i > 50) return;
        if (scope.$$destroyed) return;
        i += 1;
        if (elem.find(selector).length) {
            setFrameContainerSizeClasses();
        } else {
            setTimeout(ensureSetInitially, 200);
        }
    }

    $(window).on(`resize.${scope.$id}`, setFrameContainerSizeClasses);
    scope.$on('$destroy', () => $(window).off(`resize.${scope.$id}`));
    ensureSetInitially();
}
