
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson = window.Smartly.locales.modules.en.back_royal.lessons.lesson || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.lesson_finish_screen_demo_content = window.Smartly.locales.modules.en.back_royal.lessons.lesson.lesson_finish_screen_demo_content || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.lesson_finish_screen_demo_content = {...window.Smartly.locales.modules.en.back_royal.lessons.lesson.lesson_finish_screen_demo_content, ...{
    "start_free_trial": "Start your free trial",
    "learn_more": "Learn more"
}
}
    