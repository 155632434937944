
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson = window.Smartly.locales.modules.en.back_royal.lessons.lesson || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.lesson_finish_screen_footer = window.Smartly.locales.modules.en.back_royal.lessons.lesson.lesson_finish_screen_footer || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.lesson_finish_screen_footer = {...window.Smartly.locales.modules.en.back_royal.lessons.lesson.lesson_finish_screen_footer, ...{
    "additional_thoughts": "additional thoughts (optional)",
    "almost_there": "Almost there! Score {{minimumScoreInt}}% or higher to complete this SMARTCASE.",
    "boring": "Boring",
    "challenging": "Challenging",
    "confusing": "Confusing",
    "continue": "Continue",
    "easy": "Easy",
    "fast": "Fast",
    "feedback_submitted": "Feedback submitted. <strong>Thank you!</strong>",
    "fun": "Fun",
    "give_feedback": "<strong>Give feedback</strong> on this lesson",
    "next": "Next",
    "next_lesson": "NEXT LESSON:",
    "not_useful": "Not Useful",
    "ok_why": "OK! Why the thumbs down?",
    "retry_smartcase": "RETRY SMARTCASE",
    "submit_feedback": "SUBMIT<br/>FEEDBACK",
    "thanks_why": "Thanks! Why the thumbs up?",
    "too_easy": "Too Easy",
    "too_hard": "Too Hard",
    "useful": "Useful",
    "next_lesson_or_smartcase": "{isSmartCase, select, true{CONTINUE TO SMARTCASE:} other{NEXT LESSON:}}"
}
}
    