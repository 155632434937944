
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson = window.Smartly.locales.modules.en.back_royal.lessons.lesson || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.lesson_start_screen = window.Smartly.locales.modules.en.back_royal.lessons.lesson.lesson_start_screen || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.lesson_start_screen = {...window.Smartly.locales.modules.en.back_royal.lessons.lesson.lesson_start_screen, ...{
    "welcome": "WELCOME!",
    "what_you_will_learn": "WHAT YOU'LL LEARN IN THIS LESSON",
    "score_an_x": "Score {{minimumScoreInt}}% or higher to complete this SMARTCASE"
}
}
    