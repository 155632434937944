
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.toggleable_course_list_buttons = window.Smartly.locales.modules.en.back_royal.lessons.stream.toggleable_course_list_buttons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.toggleable_course_list_buttons = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.toggleable_course_list_buttons, ...{
    "flat_list": "Flat List",
    "grouped_by_topic": "Grouped by Topic",
    "playlist": "Active Playlist"
}
}
    