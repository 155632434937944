import { getErrorBoundary, type ErrorHandlingOptions } from 'FrontRoyalErrorBoundary';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { type FC, memo, Suspense, useCallback, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

/*
    LoadingBoundary combines an ErrorBoundary and a SuspenseBoundary. It can be used whenever we want to
    block a particular part of the UI from rendering until everything within it is ready.
*/

const SpinnerComponent: FC = () => (
    // useEffect(() => {
    //     console.log('spinner mounting');
    //     return () => {
    //         console.log('spinner unmounting');
    //     };
    // }, []);
    <FrontRoyalSpinner />
);
const Spinner = memo(SpinnerComponent) as typeof SpinnerComponent;

function LoadingBoundaryComponent({
    children,
    errorActions,
}: {
    errorActions: ErrorHandlingOptions;
    children: React.ReactNode;
}) {
    const retry = useCallback(() => {
        // See https://trello.com/c/D7HjOuv1/85-feat-implement-disconnected-error-handling-in-the-subcomponent-case
        throw new Error('Retry not implemented');
    }, []);

    return (
        <ErrorBoundary FallbackComponent={getErrorBoundary({ ...errorActions, retry })}>
            <Suspense fallback={<Spinner />}>{children}</Suspense>
        </ErrorBoundary>
    );
}

export const LoadingBoundary = memo(LoadingBoundaryComponent) as typeof LoadingBoundaryComponent;
