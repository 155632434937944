
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson = window.Smartly.locales.modules.en.back_royal.lessons.lesson || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.show_frames_player = window.Smartly.locales.modules.en.back_royal.lessons.lesson.show_frames_player || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.show_frames_player = {...window.Smartly.locales.modules.en.back_royal.lessons.lesson.show_frames_player, ...{
    "begin": "{optionValue, select, true{BEGIN SMARTCASE} other{GET STARTED}}",
    "can_keep_waiting": "You can keep waiting or click to exit.",
    "estimated_time": "Estimated time: {{mins}} mins",
    "exit_lesson": "Exit Lesson",
    "hint": "Give me a hint",
    "while_to_load": "This screen is taking a while to load ...",
    "begin_test": "BEGIN TEST",
    "explain": "Explain this screen",
    "aiTutor": "{{ai}} TUTOR",
    "review_this_lesson": "Review this lesson",
    "recap_last_lesson": "Recap last lesson",
    "return_to_screen": "Return to Screen",
    "begin_exam": "BEGIN EXAM",
    "chat_with_ai": "Chat with {{ai}} Tutor",
    "review_with_ai": "Review with {{ai}} Tutor"
}
}
    