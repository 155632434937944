
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_dashboard_next_up = window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_dashboard_next_up || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_dashboard_next_up = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_dashboard_next_up, ...{
    "chapter_x": "CHAPTER {{num}}",
    "lesson_x": "LESSON {{num}}",
    "next_up": "NEXT UP:"
}
}
    