
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.show_stream = window.Smartly.locales.modules.en.back_royal.lessons.stream.show_stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.show_stream = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.show_stream, ...{
    "cannot_be_launched": "We're sorry, but this lesson cannot be launched with your current version of {{brandName}}.",
    "go_back": "Go back"
}
}
    