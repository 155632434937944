
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.shared = window.Smartly.locales.modules.en.back_royal.lessons.shared || {};
window.Smartly.locales.modules.en.back_royal.lessons.shared.content_access_helper = window.Smartly.locales.modules.en.back_royal.lessons.shared.content_access_helper || {};
window.Smartly.locales.modules.en.back_royal.lessons.shared.content_access_helper = {...window.Smartly.locales.modules.en.back_royal.lessons.shared.content_access_helper, ...{
    "locked": "LOCKED",
    "reason_message_stream_default": "This course cannot be opened at this time.",
    "reason_title_stream_default": "Course Locked",
    "reason_message_playlist_default": "This concentration cannot be opened at this time.",
    "reason_title_playlist_default": "Playlist Locked",
    "reason_message_lesson_default": "This lesson cannot be opened at this time.",
    "reason_title_lesson_default": "Lesson Locked",
    "reason_message_stream_no_user": "This course is available as part of the full degree program.",
    "reason_title_stream_no_user": "Locked Course",
    "reason_message_stream_locked": "This course is available as part of the full degree program.",
    "reason_title_stream_locked": "Locked Course",
    "reason_message_stream_unavailable_offline": "This course is unavailable while offline.",
    "reason_message_lesson_unavailable_offline": "This lesson is unavailable while offline.",
    "reason_title_stream_unavailable_offline": "Unavailable Offline",
    "reason_title_lesson_unavailable_offline": "Unavailable Offline",
    "reason_message_lesson_coming_soon": "This lesson cannot be opened at this time.",
    "reason_message_stream_coming_soon": "This course cannot be opened at this time.",
    "reason_title_stream_coming_soon": "Course Locked",
    "reason_message_stream_unmet_prerequisites_playlist": "To unlock this exam, complete all prior courses in the appropriate playlist.",
    "reason_message_stream_unmet_prerequisites_concentration": "To unlock this exam, complete all prior courses in the appropriate concentration.",
    "reason_message_stream_unmet_prerequisites_specialization": "To unlock this exam, complete all prior courses in the appropriate specialization.",
    "reason_title_stream_unmet_prerequisites_playlist": "Exam Locked",
    "reason_title_stream_unmet_prerequisites_concentration": "Exam Locked",
    "reason_title_stream_unmet_prerequisites_specialization": "Exam Locked",
    "reason_message_stream_unmet_prerequisites_schedule": "To unlock this exam, complete all prior required courses in your class schedule.",
    "reason_title_stream_unmet_prerequisites_schedule": "Exam Locked",
    "reason_title_stream_must_switch_program_to_launch_exam": "Exam Locked",
    "reason_message_stream_must_switch_program_to_launch_exam": "To unlock this exam, please switch to the program in which it is included.",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_schedule": "This exam will become available on {{openTimeStr}} once all prior required courses in your class schedule are completed.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_schedule": "Exam Locked",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_playlist": "This exam will become available on {{openTimeStr}} once all prior courses in the appropriate playlist are completed.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_playlist": "Exam Locked",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_concentration": "This exam will become available on {{openTimeStr}} once all prior courses in the appropriate concentration are completed.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_concentration": "Exam Locked",
    "reason_message_stream_before_launch_window_and_unmet_prerequisites_specialization": "This exam will become available on {{openTimeStr}} once all prior courses in the appropriate specialization are completed.",
    "reason_title_stream_before_launch_window_and_unmet_prerequisites_specialization": "Exam Locked",
    "reason_message_stream_before_launch_window": "This exam will become available on {{openTimeStr}}.",
    "reason_title_stream_before_launch_window": "Exam Locked",
    "reason_title_stream_requires_idology_verification": "Course Locked",
    "reason_message_stream_requires_idology_verification": "To unlock, complete your identity verification.",
    "reason_title_stream_requires_signed_tila_disclosure": "Course Locked",
    "reason_message_stream_requires_signed_tila_disclosure": "To unlock, sign your payment plan disclosure.",
    "reason_message_lesson_completed_test": "This exam is now closed.",
    "reason_title_lesson_completed_test": "Exam Locked",
    "reason_message_lesson_no_user": "This lesson is available as part of the full degree program.",
    "reason_title_lesson_no_user": "Lesson Locked",
    "reason_message_lesson_exam_time_run_out": "This exam is now closed.",
    "reason_title_lesson_exam_time_run_out": "Exam Locked",
    "reason_message_playlist_requires_idology_verification": "To unlock, complete your identity verification.",
    "reason_message_playlist_requires_signed_tila_disclosure": "To unlock, sign your payment plan disclosure.",
    "completed": "COMPLETED",
    "locked_curriculum_message": "The full curriculum unlocks upon commencement of the program.",
    "locked_curriculum_message_rejected": "Full curriculum only available as part of the {{programTitle}} program.",
    "locked_curriculum_message_payment_overdue": "Your curriculum is locked because our records show your account is missing one or more required payments.",
    "reason_title_stream_placed_on_academic_hold": "Course Locked",
    "reason_message_stream_placed_on_academic_hold": "You have been placed on academic hold. You must <a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">meet with an Academic Advisor</a> to have the hold removed.",
    "reason_message_playlist_placed_on_academic_hold": "You have been placed on academic hold. You must <a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">meet with an Academic Advisor</a> to have the hold removed.",
    "reason_message_lesson_requires_biosig_verification": "You must verify your identity.",
    "reason_title_lesson_requires_biosig_verification": "Exam Locked"
}
}
    