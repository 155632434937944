import { type LessonImageFormats, type StreamImageFormats } from './Lessons.types';

// When the image formats object gets camelized coming down from the http api and then re-snakecased (for example, in lessonToIguanaAttrs),
// The keys in image.formats go from 84x33 -> 84X33 -> 84_x33. We need to change them back to 84x33.
export function fixSnakeCasedFormats<OutputFormats extends LessonImageFormats | StreamImageFormats>(
    formats: Record<string, OutputFormats[keyof OutputFormats]>,
): OutputFormats {
    return Object.keys(formats).reduce<OutputFormats>((imageFormats, formatKey) => {
        const fixedKey = formatKey.replace('_', '') as keyof OutputFormats;
        imageFormats[fixedKey] = formats[formatKey] as unknown as OutputFormats[keyof OutputFormats];
        return imageFormats;
    }, {} as OutputFormats);
}
