
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.dashboard_footer = window.Smartly.locales.modules.en.back_royal.lessons.stream.dashboard_footer || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.dashboard_footer = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.dashboard_footer, ...{
    "about": "About",
    "available_app_store": "Available App Store",
    "blog": "Blog",
    "coming_play": "Coming Google Play",
    "contact_us": "Contact Us",
    "copyright": "© Quantic Holdings, Inc. All rights reserved.",
    "cookies": "Cookies",
    "companies": "For Companies",
    "facebook": "Facebook",
    "facebook_icon": "Facebook Icon",
    "faq": "FAQ",
    "generate_tweet": "Generate a new tweet!",
    "homepage": "Homepage",
    "courses": "Courses",
    "method": "Method",
    "organizations": "Organizations",
    "press": "Press",
    "pricing": "Pricing",
    "privacy_policy": "Privacy Policy",
    "share_with_classmates": "Share with your classmates",
    "terms_and_conditions": "Terms and Conditions",
    "tweet": "Tweet",
    "twitter": "Twitter",
    "twitter_icon": "Twitter Icon",
    "universities": "For Universities"
}
}
    