
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson = window.Smartly.locales.modules.en.back_royal.lessons.lesson || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.offline_mode_consent_modal = window.Smartly.locales.modules.en.back_royal.lessons.lesson.offline_mode_consent_modal || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.offline_mode_consent_modal = {...window.Smartly.locales.modules.en.back_royal.lessons.lesson.offline_mode_consent_modal, ...{
    "header": "Before you begin...",
    "caption": "{{brandName}} can proactively download your curriculum for study when you're offline. Would you like to enable this feature?",
    "fine_print": "You can change this at any time<br /> by visiting your Preferences.",
    "enabled": "Enabled",
    "disabled": "Disabled",
    "button_text": "Save and Continue"
}
}
    