export function lessonLaunchPath({
    lessonId,
    streamId,
    chapterIndex,
}: {
    lessonId: string;
    streamId: string;
    chapterIndex: number;
}) {
    return `/course/${streamId}/chapter/${chapterIndex}/lesson/${lessonId}/show`;
}

export function streamDashboardPath({ streamTitle, streamId }: { streamTitle: string; streamId: string }) {
    return `/course/${streamTitle.toLowerCase().replace(/[\W_]+/g, '-')}/${streamId}`;
}

export function streamEditorPath({ streamId }: { streamId: string }) {
    return `/editor/course/${streamId}/edit`;
}
