
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson = window.Smartly.locales.modules.en.back_royal.lessons.lesson || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.assessment_lesson_end = window.Smartly.locales.modules.en.back_royal.lessons.lesson.assessment_lesson_end || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.assessment_lesson_end = {...window.Smartly.locales.modules.en.back_royal.lessons.lesson.assessment_lesson_end, ...{
    "total_time": "Total Time",
    "score": "Score",
    "challenges": "Challenges"
}
}
    