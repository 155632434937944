
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_completed_link_box = window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_completed_link_box || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_completed_link_box = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_completed_link_box, ...{
    "download": "Download",
    "signing": "Signing",
    "certificate": "Certificate",
    "completed_at_x": "Completed: {{completed_at}}",
    "summaries": "Summaries"
}
}
    