
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_boxes = window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_boxes || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_boxes = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_boxes, ...{
               
    "heading_admissions_info": "Admissions Question?",
    "heading_admissions_timeline": "",
    "heading_admitted_students_zoom_save_the_date": "Save the Date!",
    "heading_admitted_students_zoom_q_and_a": "Incoming Students Q&A",
    "heading_complete_application": "Apply to {{institutionName}}",
    "heading_company_funding": "{{featuredCompanyName}} Tuition Funding",
    "heading_employer_funding": "Employer Tuition Funding",
    "heading_find_friends": "Find Friends in {{institutionName}}",
    "heading_find_company_students": "Find {{companyName}} Students",
    "heading_follow_us": "Follow {{institutionName}}",
    "heading_our_degree_programs": "Our Degree Programs",
    "heading_our_tuition_model": "Our Tuition Model",
    "heading_register_for_program": "",
    "heading_not_joining_program_can_reapply": "",
    "heading_not_joining_program_cannot_reapply": "",
    "heading_schedule_your_interview": "",
    "heading_scholarship_opportunities": "Scholarship Opportunities",
    "heading_upload_documents": "Upload Documents",
    "heading_view_upcoming_events": "View Upcoming Events",
    "heading_view_featured_events": "View Featured Events",
    "heading_upcoming_events": "Upcoming Events",
    "heading_slack": "This Week In Slack",
    "heading_recent_courses": "Recent Courses",
    "heading_enrollment": "Complete Your Enrollment",
    "heading_identity_verification": "Identity Verification",
    "heading_diploma_check": "Diploma Check",
    "heading_digital_certificate_check": "Certificate Check",
    "heading_diploma_frames": "Diploma Frames",
    "heading_transcripts": "Transcripts",
    "heading_prepare_for_graduation": "Prepare for Graduation",
    "heading_check_your_certificate": "Check your Certificate",
    "heading_congrats_you_have_graduated": "<span class=\"subtitle\">Congratulations!</span> <span>You have Graduated</span>",
    "heading_the_program_title": "The {{programTitle}}",
    "heading_start_date": "Start Date: {{startDate}}",
    "heading_accepted": "<span class=\"subtitle\">Congratulations!</span> <span>You've Been Accepted</span>",
    "heading_executive_education": "New! Executive Education",
    "heading_mobile_identity_verification": "Identity Verification",
    "heading_mobile_offline": "<span class=\"tw-text-xs\"><i class=\"fa fa-cloud-slash tw-inline-block tw-me-2\"></i>You Are Offline</span>",
    "heading_mobile_schedule_interview": "Schedule Your Interview",
    "heading_mobile_conduct_interview": "Conduct Your Interview",
    "heading_late_project_submission": "Last Week's {num, plural, =1{Project} other{Projects}}",
    "heading_open_courses": "Our Open Courses",

                   
    "content_admissions_info": "Email us at <a ng-click=\"onClick('{{admissionsEmail}}', 'email')\">{{admissionsEmail}}</a> or schedule a <a ng-click=\"onClick('{{scheduleVideoChatUrl}}', 'schedule_video_chat')\">1:1 video chat</a> with a member of our team.",
    "content_admissions_timeline": "",
    "content_admitted_students_zoom": "",
    "content_complete_application": "The deadline for the next admission cycle is approaching.",
    "content_company_funding": "Some employees are eligible for {{featuredCompanyName}} tuition benefits. Our funding team can advise and facilitate contact with HR. Reach us at {{fundingEmailAddress}}.",
    "content_employer_funding": "Some companies invest in employees by sponsoring their tuition. Our funding team can advise and facilitate contact with HR. Reach us at {{fundingEmailAddress}}.",
    "content_find_friends": "Browse the alumni network for colleagues and friends.",
    "content_follow_us": "Find students, news, events, and alumni stories on our LinkedIn page.",
    "content_our_degree_programs": "Learn more about {{institutionName}}'s innovative degree programs disrupting traditional higher education.",
    "content_our_tuition_model": "We're selective, but affordable if admitted. Learn how we're broadening access to top tier education with an innovative tuition model.",
    "content_register_for_program": "",
    "content_not_joining_program_can_reapply": "",
    "content_not_joining_program_cannot_reapply": "",
    "content_schedule_your_interview": "",
    "content_upload_documents": "If admitted, we’ll need official transcripts for your highest degree. Get a head start now.",
    "content_view_upcoming_events": "Students attend virtual and in-person events around the world.",
    "content_admitted_students_zoom_you_are_invited": "You're invited to an Incoming Students Zoom Q&A on {{webinarMonthDay}}. It is optional, though all admitted students are strongly encouraged to attend.",
    "content_admitted_students_if_you_missed_the_zoom": "If you missed the Zoom Q&A for all admitted students, it's not too late.",
    "content_diploma_check": "Does your name appear the way you'd like it to be shown on your diploma? Is the mailing address accurate?",
    "content_diploma_check_digital_only": "Does your name appear the way you'd like it to be shown on your diploma?",
    "content_digital_certificate_check": "Does your name appear the way you'd like it to be shown on your certificate?",
    "content_diploma_frames": "Showcase your achievement. Browse diploma frames.",
    "content_transcripts": "Do you need an official or unofficial copy of your Quantic transcript?",
    "content_open_courses_business_admin": "Business Foundations use humor and interactivity to teach and then cement foundational business concepts. Progress is factored into both admission and scholarship decisions.",
    "content_open_courses_business_analytics": "MSBA Foundations use humor and interactivity to teach and then cement foundational business analytics concepts. Progress is factored into both admission and scholarship decisions.",
    "content_open_courses_software_engineering": "MSSE Foundations use humor and interactivity to teach and then cement foundational software engineering concepts. Progress is factored into both admission and scholarship decisions.",

              
    "button_preview_the_network": "Preview the Network",
    "button_view_students_and_alumni": "View Students and Alumni",
    "button_follow_us": "Follow Us",
    "button_the_mba": "The MBA",
    "button_the_executive_mba": "The Executive MBA",
    "button_msba": "MS Business Analytics",
    "button_msse": "MS Software Engineering",
    "button_learn_more": "Learn More",
    "button_complete_application": "Complete Application",
    "button_view_events": "View Events",
    "button_view_featured_events": "View Featured Events",
    "button_view_and_schedule": "View and Schedule",
    "watch_recording": "Watch Recording",
    "button_contact_us": "Contact Us",
    "button_upcoming_events": "{{hasMoreEvents ? 'View More Events' : 'View All Events' }}",
    "button_next_steps": "Next Steps",
    "button_update_name": "Update Name",
    "button_update_address": "Update Address",
    "button_browse_frames": "Browse Frames",
    "button_share": "Share",
    "button_view_details": "View Details",
    "button_view_programs": "View Programs",
    "button_schedule": "Schedule",
    "button_conduct_interview": "Conduct Interview",
    "button_more": "More",

           
    "not_available_while_offline": "Not available while offline.",
    "close": "Close",
    "view_event_tooltip_details_unavailable": "Event details are only available to MBA and Executive MBA students and alumni.",

                          
    "schedule_video_chat": "Schedule a <a ng-click=\"onClick('{{link}}', '{{type}}')\">1:1 video chat</a>",
    "email": "Email <a ng-click=\"onClick('{{link}}', '{{type}}')\">{{link}}</a>",
    "call_us": "Call/WhatsApp us at <a ng-click=\"onClick('{{link}}', '{{type}}')\">{{link}}</a>",
    "contact_us": "Contact Us",
    "chat_with_admissions": "Chat live with <a ng-click=\"onClick('{{link}}', '{{type}}')\">Admissions</a> now",
    "quick_answers": "For Quick Answers",
    "more_details": "For More Details",

                          
    "status": "Status",
    "start_date": "Start Date",
    "view_full_schedule": "View Full Schedule",
    "schedule_coming_soon": "Schedule Coming Soon",
    "welcome_to_the": "Welcome to the",
    "week_0_message": "Now’s a good time to take a look at the program schedule and perhaps get a head start on courses. Don’t forget to register on Slack!",
    "week_0_message_no_slack": "Now’s a good time to take a look at the program schedule and perhaps get a head start on courses.",
    "week_1_message": "Check back here each week to see your curriculum status and assignments.",
    "not_on_track": "Not on Track",
    "on_track": "On Track",
    "on_track_finished": "On Track",
    "almost_there": "Almost There!",
    "missing_graduation_requirements": "Missing Graduation Requirements",
    "academic_probation": "Academic Probation",
    "check_schedule": "Check the schedule below for this week’s assignments.",
    "good_work": "Keep up the good work!",
    "complete_the_following": "Complete the following {num, plural, =1{course} other{courses}}:",
    "num_more_courses": "+ {num} more {num, plural, =1{course} other{courses}}",
    "show_less": "- show less",
    "submit_the_following_projects": "Submit the following projects or presentations:",
    "submit_to_email": "Submit to: <a href=\"mailto:{{projectSubmissionEmail}}\">{{projectSubmissionEmail}}</a>",
    "submit_project": "Submit Project",
    "no_project_documents": "No project documents.",
    "graduation_date": "Graduation Date",
    "finished": "Finished",
    "program_completion_date": "Program Completion Date",
    "certificate_completion_date": "Certificate Completion Date",
    "complete_diploma_check": "Your graduation date is coming up! Please be sure you have completed all program requirements as well as the Diploma Check items below.",
    "you_will_be_notified_when_cert_is_available": "You will receive an email notification when your digital certificate is available. Please be sure to complete the Certificate Check items below.",
    "failed_unfortunately": "Unfortunately, you did not meet the graduation requirements. You will retain access to your course library. For more information, contact us at <a href=\"mailto:{{brandEmail}}\" target=\"_blank\">{{brandEmail}}</a>.",
    "num_more_projects": "+ {num} more {num, plural, =1{project} other{projects}}",
    "graduation_show_date": "Graduation Date: {{graduationDate}}",
    "program_completion_show_date": "Program Completion Date: {{graduationDate}}",
    "certificate_show_date": "Certificate Completion Date: {{graduationDate}}",
    "submit_projects_in_case": "In case you haven't already, please submit the following {num, plural, =1{project or presentation} other{projects or presentations}}:",
    "contact_academic_advising": "Contact <a href=\"mailto:{{academicAdvisingEmail}}\">{{academicAdvisingEmail}}</a> to discuss a plan to clear your probationary status or schedule an appointment <a href=\"https://calendly.com/academic-advising-5/academic-probation-advising\" target=\"_blank\">here</a>.",
    "complete_num_specializations": "Please complete {num} more {num, plural, =1{specialization} other{specializations}} to meet graduation requirements."
}
}
    