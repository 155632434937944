
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_completed_courses = window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_completed_courses || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_completed_courses = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_completed_courses, ...{
    "completed_courses": "COMPLETED COURSES",
    "earn_certificates": "Complete courses to earn certificates and downloadable summaries!",
    "show_all": "SHOW ALL"
}
}
    