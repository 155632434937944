
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_link_box = window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_link_box || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_link_box = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.stream_link_box, ...{
    "avg_lesson_time": "Avg Lesson Time",
    "time_limit": "Time Limit",
    "cancel": "CANCEL",
    "coming_soon": "COMING SOON",
    "coming_soon_modal": "The {{brandName}} content team is hard at work on <strong>{{title}}</strong>!<br><br>Would you like to be notified when this course is available?",
    "coming_soon_modal_cordova": "The {{brandName}} content team is hard at work on {{title}}!\n\nWould you like to be notified when this course is available?",
    "coming_soon_title": "Coming Soon",
    "completed_on": "COMPLETED ON",
    "favorite": "{optionValue, select, true{Remove From} other{Add to}} My Courses",
    "lessons": "Lessons",
    "lessons_completed": "Lessons completed",
    "let_you_know": "Thanks! We'll let you know when this course is available.",
    "minute_abbr": "MIN",
    "hours_abbr": "HRS",
    "new": "NEW",
    "ok": "OK",
    "updated": "UPDATED",
    "beta": "BETA",                                                           
    "notify": "NOTIFY ME",
    "lesson_completion_count": "{{completed}} of {{count}} lessons completed"
}
}
    