
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_identity_verification = window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_identity_verification || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_identity_verification = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_identity_verification, ...{
    "identity": "Identity",
    "verification": "verification",
    "in_order_to_continue": "In order to continue, you will need to complete an identity verification process.",
    "verify_identity": "Verify Identity"
}
}
    