
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.certificate_helper_mixin = window.Smartly.locales.modules.en.back_royal.lessons.stream.certificate_helper_mixin || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.certificate_helper_mixin = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.certificate_helper_mixin, ...{
    "confirms": "This confirms that",
    "completed": "successfully completed",
    "offered": "a course of study offered by {{institutionName}}",
    "offered_quantic": "a course of study offered by Quantic School \nof Business and Technology",
    "offered_bos_high_school": "a course of study offered by {{institutionName}}\nto high school participants of the Blue Ocean Entrepreneurship Competition",
    "offered_program": "a program offered by {{institutionName}}",
    "offered_program_quantic": "a program offered offered by Quantic School \nof Business and Technology",
    "authenticity": "Authenticity of this document can be verified by contacting {{brandEmail}}",
    "certificate": "CERTIFICATE",
    "proof_of": "PROOF OF",
    "completion": "COMPLETION"
}
}
    