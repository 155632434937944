
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_welcome_box = window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_welcome_box || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_welcome_box = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_welcome_box, ...{
    "welcome_generic": "<p class=\"hr-caption\">Welcome to {{brandName}}, <br class=\"hidden-xs\">{{name}}!</p><hr class=\"hr-hexagon lightest-beige-bg hidden-xs\" /><div class=\"sub-title\"><p class=\"message smarter\">We hope you enjoy your time studying with us.<br><br>Happy Learning!</p></div>"
}
}
    