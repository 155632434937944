
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.accepted_application = window.Smartly.locales.modules.en.back_royal.lessons.stream.accepted_application || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.accepted_application = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.accepted_application, ...{
    "congrats": "CONGRATULATIONS",
    "you_have_been": "You have been",
    "accepted": "ACCEPTED",
    "to_the": "to the",
    "to": "to",
    "class_of_x": "CLASS OF {{classDateString}}",
    "share": "SHARE",
    "get_started": "GET STARTED",
    "facebook_icon": "Facebook Icon",
    "twitter_icon": "Twitter Icon"
}
}
    