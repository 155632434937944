
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_schedule_interview = window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_schedule_interview || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_schedule_interview = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_schedule_interview, ...{
    "schedule_your": "Schedule Your",
    "conduct_your": "Conduct Your",
    "interview": "Interview",
    "congratulations": "Congratulations! You have been selected for a brief admissions interview. Check your email for details.",
    "conduct_interview": "Conduct Interview",
    "required": "This is a <b>required</b> interview. Please check your email for more details.",
    "schedule_interview": "Schedule Interview",
    "schedule_your_interview": "Schedule Your Interview",
    "close": "Close <i class=\"fas fa-multiply\"></i>",
    "schedule": "Schedule <i class=\"fas fa-chevron-up\"></i>"
}
}
    