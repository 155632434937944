
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson = window.Smartly.locales.modules.en.back_royal.lessons.lesson || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.lesson_finish_screen = window.Smartly.locales.modules.en.back_royal.lessons.lesson.lesson_finish_screen || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.lesson_finish_screen = {...window.Smartly.locales.modules.en.back_royal.lessons.lesson.lesson_finish_screen, ...{
    "smartcase_complete": "SMARTCASE COMPLETE",
    "smartcase": "SMARTCASE",
    "test_complete": "TEST COMPLETE",
    "nice_work": "Nice work!",
    "check_out_x": "Check out <strong>{{title}}!</strong>",
    "key_terms": "KEY TERMS",
    "demo_message": "You just got a small sample of how fast and easy it is to learn with {{brandName}}. Enjoy access to many more lessons today, free!",
    "your_test_score_auto": "Your score will be automatically processed. If this is your final exam, you should see your results shortly on the home screen.",
    "offline_message": "You are offline, but keep going! We will sync your progress when we detect an internet connection."
}
}
    