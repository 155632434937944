
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard = window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard, ...{
    "active_playlist": "ACTIVE PLAYLIST",
    "activate_playlist": "ACTIVATE PLAYLIST",
    "bookmarked_courses": "BOOKMARKED COURSES",
    "bos": "Blue Ocean Strategy",
    "browse": "BROWSE",
    "browse_all": "BROWSE ALL",
    "browse_courses": "BROWSE Courses",
    "browse_playlists": "BROWSE PLAYLISTS",
    "close": "CLOSE",
    "completed_all_playlists": "You've completed all of our playlists!<br>Stay tuned for new courses and playlists.",
    "completed_courses_title": "Completed Courses",
    "dashboard_title": "DASHBOARD",
    "discover_bos": "Click the course below to discover Blue Ocean Strategy, a bold business strategy that rewards innovation and casts competition aside.",
    "free_to_share": "Feel free to share this page with someone you think would enjoy it, too!",
    "go_courses": "GO TO COURSES",
    "go_my_courses": "GO TO MY COURSES",
    "happy_learning": "Happy Learning!",
    "home_title": "{{institutionName}}",
    "keep_learning": "KEEP LEARNING",
    "courses": "COURSES",
    "no_bookmarked_courses": "You have no bookmarked courses.",
    "top_nav_all_complete": "All Courses Complete!",
    "top_nav_browse": "Browse Courses to add courses to your dashboard.",
    "top_nav_completed": "Course Completed!",
    "top_nav_completed_all": "You’ve completed all of your selected courses. Browse Courses for new things to learn.",
    "top_nav_playlists_completed_all": "You’ve completed all of your selected courses. Browse Courses and Playlists for new things to learn.",
    "top_nav_nice_work": "Nice Work!",
    "top_nav_uh_oh": "Uh oh! You have no courses.",
    "top_nav_well_done": "Well done! You can download your certificate or review the course below.",
    "tourtip_bos_saved": "These are your saved courses. We've added the Blue Ocean Strategy course for you.",
    "my_courses": "MY COURSES",
    "my_courses_title": "MY COURSES",
    "nice_work": "Nice Work!",
    "no_thanks": "No thanks",
    "not_sure_browse_playlists": "Not sure? <a ng-click=\"openPlaylistLibrary()\">Browse all playlists</a>",
    "playlist": "PLAYLIST",
    "playlists": "PLAYLISTS",
    "settings": "SETTINGS",
    "share_email": "Share by Email",
    "share_facebook": "Share on Facebook",
    "share_linkedin": "Share on LinkedIn",
    "share_twitter": "Share on Twitter",
    "start_learning": "START LEARNING",
    "start_tour": "START TOUR",
    "recommend_x_playlist_x_progress": "We recommend the <strong>{{title}}</strong> playlist. You've <strong>already completed {{progress}}% </strong> of its courses, so you're well on your way!",
    "recommend_x_playlist_0_progress": "We recommend the <strong>{{title}}</strong> playlist.",
    "thank_you": "Thank you, and happy learning!",
    "tourtip_bookmark": "You can add or remove a course by tapping its ribbon.",
    "tourtip_complete": "COMPLETE TOUR",
    "tourtip_dashboard": "This is your dashboard. Your saved courses are available here.",
    "tourtip_next": "Next",
    "tourtip_recommend": "This is where you'll find your next recommended course. When you finish a course, we'll suggest a new one for you to start.",
    "tourtip_saved": "These are your saved courses. We've added some courses to get you started.",
    "tourtip_playlist": "This is your playlist of courses. We've activated the MBA Fundamentals playlist to get you started.",
    "tourtip_playlist_institutions": "This is your playlist of courses. We've activated a playlist to get you started.",
    "tourtip_saved_subscriptions": "These are your saved courses. We've added a few courses to get you started.",
    "valued_beta_tester": "As a valued beta tester, you will retain unlimited access to your MBA Prep courses, even if you decide not to subscribe.",
    "view_playlist": "VIEW PLAYLIST",
    "you_have_completed_all_of_your_courses": "You’ve completed all of your courses!"
}
}
    