
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_learning_box = window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_learning_box || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_learning_box = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.student_dashboard_learning_box, ...{
    "active": "ACTIVE",
    "box_header_concentration_complete": "Great job! You completed this concentration.",
    "box_header_playlist_complete": "Great job! You completed this playlist.",
    "box_header_playlist_just_completed": "Great job! Select a playlist to continue learning.",
    "box_header_concentration_just_completed": "Great job! Select a concentration to continue learning.",
    "open_courses": "OPEN COURSES",
    "chapter": "CHAPTER",
    "chapter_x": "CHAPTER {{num}}",
    "close": "CLOSE",
    "description_degree_program": "Our {{programTitle}} delivers the knowledge and skills you need to succeed in today’s business environment. Tap any concentration to activate it and start learning.",
    "description_certificates": "The {{programTitle}} program delivers the knowledge and skills you need to succeed in today’s business environment. Tap any concentration to activate it and start learning.",
    "description_jordanian_math": "The {{programTitle}} program delivers the knowledge and skills you need to successfully pass the Tawjihi exam and prepare for college math and science.",
    "description_open_courses": "We hope you enjoy our open business courses. Tap any concentration to activate it and start learning.",
    "description_playlists": "Playlists organize {{brandName}} courses into efficient tracks focused on a goal.",
    "home_title": "{{institutionName}}",
    "keep_learning": "KEEP LEARNING",
    "lesson": "LESSON",
    "lesson_x": "LESSON {{num}}",
    "degree_program_curriculum": "{{programTitle}} CURRICULUM",
    "career_network_only_curriculum": "OPEN COURSES",
    "certificates_curriculum": "{{programTitle.toUpperCase()}}",
    "next_up": "NEXT UP:",
    "num_concentrations": "{num} {num, plural, =1{CONCENTRATION} other{CONCENTRATIONS}} • {percentComplete}% COMPLETE",
    "num_playlists": "{num} {num, plural, =1{PLAYLIST} other{PLAYLISTS}}",
    "percent_complete": "{{percent}}%",
    "playlists": "PLAYLISTS",
    "playlist_complete": "{{numCourses}} COURSES • COMPLETED",
    "playlist_in_progress": "{{numCourses}} COURSES • {{numCoursesComplete}} COMPLETED",
    "offline_courses": "OFFLINE COURSES",
    "num_offline_courses_available": "{{numCourses}} {{numCourses == 1 ? 'COURSE' : 'COURSES'}} AVAILABLE",
    "recommended": "Recommended",
    "select_concentration_title": "SELECT CONCENTRATION",
    "select_playlist_title": "SELECT PLAYLIST",
    "show_curriculum": "CURRICULUM",
    "show_courses": "SHOW COURSES",
    "show_playlists": "SHOW PLAYLISTS",
    "start": "START",
    "start_course": "START COURSE",
    "x_minutes": "{{num}} minutes"
}
}
    