
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson = window.Smartly.locales.modules.en.back_royal.lessons.lesson || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized = {...window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized, ...{
    "blanks_on_image": "Select an answer for each blank",
    "compose_blanks": "Type an answer in each blank",
    "compose_blanks_on_image": "Type an answer in each blank",
    "fill_in_the_blanks": "Select an answer for each blank",
    "matching": "Match each prompt with an answer",
    "multiple_choice_poll": "Vote to reveal poll results",
    "no_interaction": "Read and continue",
    "select_all_answers_that_apply": "Select all answers that apply",
    "select_all_answers": "Select all answers",
    "select_an": "Select an answer",
    "this_or_that": "Select an answer for each card"
}
}
    