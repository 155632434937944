import { type PublishedStream } from 'FrontRoyalStore';
import moize from 'moize';
import transformKeyCase from 'Utils/transformKeyCase';
import {
    type Stream,
    type Lesson,
    type StreamCompoundItemVersionKey,
    type StreamImageSnakeCasedFormats,
} from './Lessons.types';
import { lessonToIguanaAttrs } from './lesson';
import { fixSnakeCasedFormats } from './contentImage';

function _getOrderedLessonsFromStream<T extends boolean>(stream: Stream<T>): Lesson<T>[] {
    return stream.chapters
        .map(chapter =>
            chapter.lessonIds.map(lessonId => {
                const lesson = stream.lessons.find(_ => _.id === lessonId) as Lesson<T> | undefined;
                if (!lesson) {
                    throw new Error(`Lesson not found`);
                }
                return lesson;
            }),
        )
        .flat();
}
export const getOrderedLessonsFromStream = moize(_getOrderedLessonsFromStream);

export const camelCaseStream = (stream: PublishedStream): Stream =>
    transformKeyCase(stream, { to: 'camelCase' }) as Stream;

export const snakeCaseStream = (stream: Stream<boolean>): PublishedStream =>
    transformKeyCase(stream, { to: 'snakeCase' }) as PublishedStream;

export function getCompoundItemVersionKeyForStream(stream: Stream<boolean>): StreamCompoundItemVersionKey {
    if (!stream.publishedAt) throw new Error('Stream must have a publishedAt date');
    return {
        published_at: stream.publishedAt,
        lessons: stream.lessons.map(l => l.itemVersionKey).sort(),
        stream_lesson_tutor_bot_record_max_updated_at:
            stream.streamLessonTutorBotRecords
                ?.map(r => r.updatedAt)
                .sort()
                .reverse()[0] || null,
        schema_version: stream.schemaVersion,
    };
}

export function streamToIguanaAttrs(stream: Stream<boolean>): PublishedStream {
    const lessons = stream.lessons;

    const snakeCased = transformKeyCase<PublishedStream>(
        { ...stream, lessons: [] },
        {
            to: 'snakeCase',
        },
    );
    snakeCased.lessons = lessons.map(lesson => lessonToIguanaAttrs(lesson));

    // See comment in fixSnakeCasedFormats
    if (snakeCased.image) {
        snakeCased.image.formats = fixSnakeCasedFormats<StreamImageSnakeCasedFormats>(snakeCased.image.formats);
    }

    return snakeCased;
}

export function removeLessonContent(stream: Stream<boolean>): Stream<false> {
    return {
        ...stream,
        lessons: stream.lessons.map(lessonMaybeWithFullContent => {
            const lesson = { ...lessonMaybeWithFullContent } as Lesson<false>;
            delete lesson.frames;
            return lesson as Lesson<false>;
        }),
    };
}
