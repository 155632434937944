
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.models = window.Smartly.locales.modules.en.back_royal.lessons.models || {};
window.Smartly.locales.modules.en.back_royal.lessons.models.lesson = window.Smartly.locales.modules.en.back_royal.lessons.models.lesson || {};
window.Smartly.locales.modules.en.back_royal.lessons.models.lesson.lesson_player_view_model = window.Smartly.locales.modules.en.back_royal.lessons.models.lesson.lesson_player_view_model || {};
window.Smartly.locales.modules.en.back_royal.lessons.models.lesson.lesson_player_view_model = {...window.Smartly.locales.modules.en.back_royal.lessons.models.lesson.lesson_player_view_model, ...{
    "lesson_x_of_x": "Lesson {{index}} of {{lessonCount}}",
    "must_be_online_exit_or_retry": "This lesson can only be completed when you are online. Go online to complete this lesson.",
    "must_be_online_exit_or_retry_assessment": "SMARTCASES can only be completed when you are online. Go online to complete this lesson.",
    "must_be_online_exit_or_retry_test": "Exams can only be completed when you are online. Go online to complete this lesson.",
    "exit_lesson": "Exit Lesson",
    "retry": "Retry"
}
}
    