
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.browse_courses = window.Smartly.locales.modules.en.back_royal.lessons.stream.browse_courses || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.browse_courses = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.browse_courses, ...{
    "all_courses": "All Courses",
    "any_progress": "Any Progress",
    "any_status": "Any Status",
    "any_topic": "Any Topic",
    "available": "Available",
    "beta": "BETA",
    "coming_soon": "Coming Soon",
    "completed": "Completed",
    "courses": "COURSES",
    "filter_by_progress": "Filter By Progress",
    "filter_by_status": "Filter By Status",
    "filter_by_topic": "Filter By Topic",
    "in_progress": "In Progress",
    "courses_title": "COURSES",
    "new_courses": "New",
    "no_content_found": "Sorry! No content found matching your filters.",
    "not_started": "Not Started",
    "reset": "RESET SEARCH & FILTERS",
    "save_and_close": "Save and Close",
    "search": "Search",
    "search_placeholder": "Search Courses, Lessons, and Key Terms",
    "set_filters": "SET FILTERS",
    "set_filters_title": "Set Filters",
    "updated": "UPDATED",
    "elective": "ELECTIVE"
}
}
    