
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.top_message = window.Smartly.locales.modules.en.back_royal.lessons.stream.top_message || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.top_message = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.top_message, ...{
    "top_message_business_admin_desktop": "<p>Welcome to {{institutionName}}!</p><p>Explore the MBA and Executive MBA curriculum, our 15,000+ alumni network, and upcoming global events. Apply by the {{applicationDeadlineDate}} deadline.</p>",
    "top_message_business_admin_desktop_applied": "<p>Welcome to {{institutionName}}!</p><p>Explore the MBA and Executive MBA curriculum, our 15,000+ alumni network, and upcoming global events.</p>",
    "top_message_business_admin_mobile": "<p>Explore the curriculum and alumni network of the modern MBA.</p>",
    "top_message_msba_desktop": "<p>Welcome to {{institutionName}}!</p><p>Explore the MSBA curriculum, our 15,000+ alumni network, and upcoming global events. Apply by the {{applicationDeadlineDate}} deadline.</p>",
    "top_message_msba_desktop_applied": "<p>Welcome to {{institutionName}}!</p><p>Explore the MSBA curriculum, our 15,000+ alumni network, and upcoming global events.</p>",
    "top_message_msba_mobile": "<p>Explore the curriculum and alumni network of the modern MSBA.</p>",
    "top_message_msse_desktop": "<p>Welcome to {{institutionName}}!</p><p>Explore the MSSE curriculum, our 15,000+ alumni network, and upcoming global events. Apply by the {{applicationDeadlineDate}} deadline.</p>",
    "top_message_msse_desktop_applied": "<p>Welcome to {{institutionName}}!</p><p>Explore the MSSE curriculum, our 15,000+ alumni network, and upcoming global events.</p>",
    "top_message_msse_mobile": "<p>Explore the curriculum and alumni network of the modern MSSE.</p>",
    "top_message_generic_desktop": "<p>Welcome to {{institutionName}}!</p><p>Explore the {{programTitle}} curriculum, our 15,000+ alumni network, and upcoming global events. Apply by the {{applicationDeadlineDate}} deadline.</p>",
    "top_message_generic_desktop_applied": "<p>Welcome to {{institutionName}}!</p><p>Explore the {{programTitle}} curriculum, our 15,000+ alumni network, and upcoming global events.</p>",
    "top_message_generic_mobile": "<p>Explore the curriculum and alumni network of the modern {{programTitle}}.</p>",
    "top_message_settings_has_moved": "<p>Settings has moved!</p>"
}
}
    