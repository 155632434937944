
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson = window.Smartly.locales.modules.en.back_royal.lessons.lesson || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component.continue_button = window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component.continue_button || {};
window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component.continue_button = {...window.Smartly.locales.modules.en.back_royal.lessons.lesson.frame_list.frame.componentized.component.continue_button, ...{
    "check_answer": "Check Answer",
    "continue": "Continue",
    "skip": "Skip",
    "helpful": "Helpful",
    "not_helpful": "Not Helpful",
    "continue_cta": "{optionValue, select, assessment{Continue Smartcase} exam{Continue Exam} other{Continue}}"
}
}
    