import transformKeyCase from 'Utils/transformKeyCase';
import { camelize } from 'String';
import { type AnyObject } from '@Types';
import { type LessonImageFormats, type Lesson, type LessonSnakeCasedAttrs } from './Lessons.types';
import { fixSnakeCasedFormats } from './contentImage';

export function toSnakeCasedLesson<LessonContentLoaded extends boolean>(
    lesson: Lesson<LessonContentLoaded>,
): LessonSnakeCasedAttrs<LessonContentLoaded> {
    return transformKeyCase(lesson, { to: 'snakeCase' });
}

export function lessonToIguanaAttrs(lesson: Lesson<boolean>): LessonSnakeCasedAttrs<true> {
    const snakeCased = transformKeyCase<LessonSnakeCasedAttrs<boolean>>(lesson, {
        to: 'snakeCase',

        // Anything defined in a component with `this.key` that is camelized needs to be preserved
        exclude: ['overlayOptions', 'hasBackground', 'expectedText', 'correctThreshold', 'caseSensitive', 'fontSize'],
    });

    const keyMap = {
        shared_content_for_image2_id: 'shared_content_for_image_2_id',
        context_image2_size: 'context_image_2_size',
        content_for_image2_id: 'content_for_image_2_id',
    } as const;

    if (!snakeCased.frames) return snakeCased;

    snakeCased.frames
        .flatMap(f => f?.components)
        .forEach(component => {
            if (!component) return;

            // Some components have keys like `shared_content_for_image_2_id` or `context_image_2_size`. When those are
            // camelized (coming down from the http api) and then re-snakecased (here), they end up being
            // `shared_content_for_image2_id`, etc. We need to change them back to what they originally were.
            Object.keys(component).forEach(key => {
                const newKey = keyMap[key as keyof typeof keyMap];
                if (newKey) {
                    const knownKey = key as typeof keyMap[keyof typeof keyMap];
                    component[newKey] = component[knownKey];
                    delete component[knownKey];
                }
            });

            // The old iguana code expects the keys of the behaviors object to be uppercase camelized, so
            // snake-casing the whole object messes them up.
            if (component.behaviors) {
                const origBehaviors = component.behaviors;
                component.behaviors = Object.keys(origBehaviors).reduce<Record<string, AnyObject>>((acc, name) => {
                    acc[camelize(name, true)] = origBehaviors[name];
                    return acc;
                }, {});
            }

            // See comment in fixSnakeCasedFormats
            if (component.component_type === 'ComponentizedFrame.Image') {
                const image = component.image!;
                image.formats = fixSnakeCasedFormats<LessonImageFormats>(image.formats);
            }
        });

    return snakeCased;
}
