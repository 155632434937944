
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.lessons = window.Smartly.locales.modules.en.back_royal.lessons || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream = window.Smartly.locales.modules.en.back_royal.lessons.stream || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.upload_unofficial_transcripts_modal = window.Smartly.locales.modules.en.back_royal.lessons.stream.upload_unofficial_transcripts_modal || {};
window.Smartly.locales.modules.en.back_royal.lessons.stream.upload_unofficial_transcripts_modal = {...window.Smartly.locales.modules.en.back_royal.lessons.stream.upload_unofficial_transcripts_modal, ...{
    "submit_your_transcripts": "Submit your Transcripts",
    "fast_track_your_application": "To fast-track your application, head to the Documents area to upload your <span class=\"tooltip-anchor\" tooltip-placement=\"bottom\" tooltip-trigger=\"'outsideClick'\" uib-tooltip-template=\"'customTooltipTemplate.html'\">unofficial</span> transcripts.",
    "tooltip_message": "Unofficial transcripts can include a photograph of an official transcript taken by the student or can be obtained in digital or physical form from the relevant institution. For questions about transcripts, contact <a href=\"mailto:registrar@quantic.edu\">registrar@quantic.edu</a>.<br><br>Upon acceptance into the program, you will need to request an official transcript for your highest formal degree earned. Learn more about our <a ng-click=\"loadFaqUrl('/help/transcript-faqs#what', '_blank')\">transcript requirements</a>.",
    "go_to_documents": "Go to Documents",
    "not_now": "Not now"
}
}
    